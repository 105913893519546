import React, { Component } from "react";
import EditLogModal from "../components/EditLogModal";
import DeleteLogModal from "../components/DeleteLogModal";
import moment from "moment";
import "../styles/Teams.sass";
import { Badge } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ALL_HEADERS, OVERALL_HEADERS, TIME_FORMAT } from "../constants";
import { getName } from "../utils";

const DEFAULT_LOGS_PER_PAGE = 50;
class LogsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModal: false,
      deleteModal: false,
      log: {},
      order: 1,
      members: props.team.Roster,
      selectedMembers: [],
      selectedWorkouts: [],
      selectedTimes: [],
      selectedMeters: [],
      selectedStartDate: "",
      selectedEndDate: "",
      filters: false,
      viewSplits: true,
      headers: ALL_HEADERS,
      limit: DEFAULT_LOGS_PER_PAGE,
    };
  }

  componentWillMount = () => {
    this.setLogs(this.props.team);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.team !== this.props.team) {
      this.setLogs(this.props.team);
    }
  };

  setLogs = (team) => {
    const tableLogs = team.Logs.map((log) => ({
      ...log,
      Data: [
        getName(team.Aliases, log.User),
        moment(log.Date).format(TIME_FORMAT),
        ...log.Data,
      ],
    }));
    tableLogs.sort((a, b) => {
      if (moment(a[1]).isBefore(moment(b[1]))) {
        return 1;
      }
      return -1;
    });
    this.setState({ logs: tableLogs });
  };

  deleteLog(logid) {
    const { logs } = this.state;
    fetch(`https://ergbot-server7229.herokuapp.com/logs/delete/${logid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .catch((err) => console.err("err deleting log", err))
      .then(() => {
        const newLogs = logs.filter((log) => log._id !== logid);
        this.setState({ deleteModal: false, modal: false, logs: newLogs });
      });
  }

  saveLog(logid, newData) {
    const { logs } = this.state;
    fetch("https://ergbot-server7229.herokuapp.com/logs/edit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ id: logid, Data: newData }),
    })
      .catch((err) => console.err("err saving log", err))
      .then((resp) => resp.json())
      .then((resp) => {
        const newLog = {
          ...resp,
          Data: [
            getName(this.props.team.Aliases, resp.User),
            moment(resp.Date).format(TIME_FORMAT),
            ...resp.Data,
          ],
        };
        const logIndex = logs.findIndex((log) => log._id === logid);
        const newLogs = [
          ...logs.slice(0, logIndex),
          newLog,
          ...logs.slice(logIndex + 1),
        ];
        this.setState({ logs: newLogs });
      });
  }

  // index is the index of field to sort by, order is +1 for ascending and -1 for descending
  sort(i, order) {
    const { logs, headers } = this.state;
    logs.sort((logA, logB) => {
      let a = logA.Data[i];
      let b = logB.Data[i];
      a = a || "";
      b = b || "";
      if (i === 1) {
        a = moment(a, TIME_FORMAT);
        b = moment(b, TIME_FORMAT);
        if (a.isBefore(b)) {
          return -1 * order;
        }
        return 1 * order;
      }
      if (headers[i].indexOf("time") !== -1) {
        a = a.replace(":", ".");
        b = b.replace(":", ".");
      }
      if (i > 2) {
        a = Number(a) || a;
        b = Number(b) || b;
      }
      if (a <= b) {
        return -1 * order;
      }
      return 1 * order;
    });
    this.setState({ sortIndex: i, order, logs });
  }

  toCSV() {
    const { logs, selectedMembers, selectedStartDate, selectedEndDate } =
      this.state;
    let csvRows = [ALL_HEADERS];
    for (const log of logs) {
      if (this.applyFilters(log)) {
        const values = log.Data.map((val) =>
          val ? val.replace(",", ".").replace("#", "") : ""
        );
        csvRows.push(values);
      }
    }

    let csv = csvRows.map((arr) => arr.join(",")).join("\n");

    const filename = `${this.props.team.TeamName}${
      selectedMembers.length
        ? `-${selectedMembers
            .map((user) => user.Name)
            .join("+")
            .replaceAll(" ", "_")}`
        : ""
    }${
      selectedStartDate
        ? `-starting-${selectedStartDate.format("MM-DD-yyyy")}`
        : ""
    }${
      selectedEndDate ? `-ending-${selectedEndDate.format("MM-DD-yyyy")}` : ""
    }-logs.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }

    const data = encodeURI(csv);

    const link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  }

  applyFilters(log) {
    const { Data, User, Date: date } = log;
    const {
      selectedMembers,
      selectedStartDate,
      selectedEndDate,
      selectedWorkouts,
      selectedTimes,
      selectedMeters,
    } = this.state;
    if (
      selectedMembers.length &&
      !selectedMembers.map((m) => m._id).includes(User?._id)
    )
      return false;
    if (selectedStartDate && moment(date).isSameOrBefore(selectedStartDate))
      return false;
    if (selectedEndDate && moment(date).isAfter(selectedEndDate)) return false;
    if (
      selectedWorkouts.length &&
      !selectedWorkouts.map((w) => w.name).includes(Data[2])
    )
      return false;
    if (
      selectedTimes.length &&
      !selectedTimes.map((t) => t.name).includes(Data[3])
    )
      return false;
    if (
      selectedMeters.length &&
      !selectedMeters.map((m) => m.name).includes(Data[4])
    )
      return false;
    return true;
  }

  render() {
    const { isAdmin } = this.props;
    const {
      logs,
      editModal,
      deleteModal,
      log,
      order,
      sortIndex,
      members,
      selectedMembers,
      selectedStartDate,
      selectedEndDate,
      selectedWorkouts,
      selectedTimes,
      selectedMeters,
      filters,
      viewSplits,
      headers,
      limit,
    } = this.state;
    const numFilters =
      (selectedMembers.length ? 1 : 0) +
      (selectedWorkouts.length ? 1 : 0) +
      (selectedTimes.length ? 1 : 0) +
      (selectedMeters.length ? 1 : 0) +
      (selectedStartDate ? 1 : 0) +
      (selectedEndDate ? 1 : 0);

    const uniqueWorkouts = new Set(logs.map((l) => l.Data[2]));
    const workouts = [...uniqueWorkouts]
      .filter((w) => !!w)
      .sort((a, b) => a - b)
      .map((w) => ({ name: w }));

    const uniqueTimes = new Set(logs.map((l) => l.Data[3]));
    const times = [...uniqueTimes]
      .filter((t) => !!t)
      .sort((a, b) => a - b)
      .map((t) => ({ name: t }));

    const uniqueMeters = new Set(logs.map((l) => l.Data[4]));
    const meters = [...uniqueMeters]
      .filter((m) => !!m)
      .sort((a, b) => a - b)
      .map((m) => ({ name: m }));

    let renderedLogs = 0;

    return (
      <div style={{ width: "100%", overflow: "auto" }}>
        <div className="log-header-container">
          <div>
            <b
              className="link"
              onClick={() => this.setState({ filters: !filters })}
            >
              {!filters && numFilters > 0
                ? `Filter Logs (${numFilters} applied)`
                : "Filter Logs"}
              {filters ? (
                <i class="fas fa-caret-up" style={{ marginLeft: 5 }}></i>
              ) : (
                <i class="fas fa-caret-down" style={{ marginLeft: 5 }}></i>
              )}
            </b>
            {filters && (
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ marginRight: "5%" }}>
                  <div style={{ width: 300, margin: 10 }}>
                    <Typeahead
                      id="basic-typeahead-multiple"
                      labelKey={(option) => option.Name || option.Email}
                      multiple
                      onChange={(selections) =>
                        this.setState({ selectedMembers: selections })
                      }
                      options={members || []}
                      placeholder="Search for a team member"
                      selected={selectedMembers}
                    />
                  </div>
                  <div style={{ width: 300, margin: 10 }}>
                    <Typeahead
                      id="basic-typeahead-multiple"
                      labelKey="name"
                      multiple
                      onChange={(selections) =>
                        this.setState({ selectedWorkouts: selections })
                      }
                      options={workouts}
                      placeholder="Search for a workout name"
                      selected={selectedWorkouts}
                    />
                  </div>
                  <div style={{ width: 300, margin: 10 }}>
                    <Typeahead
                      id="basic-typeahead-multiple"
                      labelKey="name"
                      multiple
                      onChange={(selections) =>
                        this.setState({ selectedTimes: selections })
                      }
                      options={times}
                      placeholder="Search for an overall time"
                      selected={selectedTimes}
                    />
                  </div>
                  <div style={{ width: 300, margin: 10 }}>
                    <Typeahead
                      id="basic-typeahead-multiple"
                      labelKey="name"
                      multiple
                      onChange={(selections) =>
                        this.setState({ selectedMeters: selections })
                      }
                      options={meters}
                      placeholder="Search for an overall meter"
                      selected={selectedMeters}
                    />
                  </div>
                </div>
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Start Date"
                      format="MM/DD/yyyy"
                      value={selectedStartDate || moment()}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={(date) =>
                        this.setState({
                          selectedStartDate: date.set({ hour: 0, minute: 0 }),
                        })
                      }
                      style={{ marginRight: 5 }}
                    />
                    {selectedStartDate && (
                      <Badge
                        bg="success"
                        style={{ marginTop: 7 }}
                        onClick={() =>
                          this.setState({ selectedStartDate: undefined })
                        }
                      >
                        {selectedStartDate.format("MM/DD/yyyy")}
                        <i class="fas fa-times badge-icon"></i>
                      </Badge>
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="End Date"
                      format="MM/DD/yyyy"
                      value={selectedEndDate || moment()}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={(date) =>
                        this.setState({
                          selectedEndDate: date.set({ hour: 23, minute: 59 }),
                        })
                      }
                      style={{ marginRight: 5 }}
                    />
                    {selectedEndDate && (
                      <Badge
                        bg="success"
                        style={{ marginTop: 7 }}
                        onClick={() =>
                          this.setState({ selectedEndDate: undefined })
                        }
                      >
                        {selectedEndDate.format("MM/DD/yyyy")}
                        <i class="fas fa-times badge-icon"></i>
                      </Badge>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <button className="link" onClick={() => this.toCSV()}>
              <i className="fas fa-download" style={{ marginRight: 5 }} />
              Download CSV
            </button>
          </div>
        </div>

        <div className="splits-container">
          <div className="splits-button">
            {viewSplits ? (
              <button
                className="link icon-right"
                onClick={() =>
                  this.setState({
                    viewSplits: false,
                    headers: OVERALL_HEADERS,
                  })
                }
              >
                View Overall Only<i class="fa-solid fa-arrow-left"></i>
              </button>
            ) : (
              <button
                className="link icon-right"
                onClick={() =>
                  this.setState({ viewSplits: true, headers: ALL_HEADERS })
                }
              >
                View Splits<i class="fa-solid fa-arrow-right"></i>
              </button>
            )}
          </div>
          {isAdmin && (
            <p style={{ fontSize: 12, marginTop: 30 }}>
              {" "}
              Click on a row to view log photo and edit the log.{" "}
            </p>
          )}
        </div>
        <table
          className={isAdmin ? "table table-hover" : "table"}
          style={{ fontSize: 14, overflow: "scroll", width: 1800 }}
        >
          <thead>
            <tr>
              <th />
              {headers.map((header, i) => (
                <th
                  onClick={() => this.sort(i, order * -1)}
                  className={i === sortIndex ? "sorted-header" : "table-header"}
                >
                  {header + " "}
                  {i === sortIndex &&
                    (order === -1 ? (
                      <i className="fas fa-chevron-down"></i>
                    ) : (
                      <i className="fas fa-chevron-up"></i>
                    ))}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => {
              if (renderedLogs > limit - 1 || !this.applyFilters(log))
                return "";
              renderedLogs++;
              return isAdmin ? (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ editModal: true, log })}
                >
                  <td>
                    {Boolean(log.Img) ? <i className="fas fa-camera"></i> : ""}
                  </td>
                  {headers.map((_, i) => (
                    <td>{log.Data[i] || ""}</td>
                  ))}
                  <td>
                    <i
                      className="far fa-times-circle icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ deleteModal: true, log });
                      }}
                    ></i>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td />
                  {headers.map((_, i) => (
                    <td>{log.Data[i] || ""}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <p>
            {" "}
            Showing {renderedLogs} / {logs.length} logs.
          </p>
          <div>
            <button
              className="link"
              onClick={() =>
                this.setState({ limit: limit + DEFAULT_LOGS_PER_PAGE })
              }
            >
              <i className="fas fa-plus" style={{ marginRight: 5 }} />
              View More
            </button>
          </div>
        </div>

        {editModal && (
          <EditLogModal
            open={editModal}
            // remove user and date from data
            data={log.Data.slice(2)}
            img={log.Img}
            onDelete={() => {
              this.deleteLog(log._id);
              this.setState({ editModal: false });
            }}
            onClose={() => this.setState({ editModal: false })}
            onSave={(data) => {
              this.saveLog(log._id, data);
              this.setState({ editModal: false });
            }}
          />
        )}

        {deleteModal && (
          <DeleteLogModal
            open={deleteModal}
            deleteLog={this.deleteLog.bind(this)}
            logid={log._id}
            close={() => this.setState({ deleteModal: false })}
          />
        )}
      </div>
    );
  }
}

export default LogsTable;
