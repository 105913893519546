import React, { Component } from "react";
import { DropdownMenu, MenuItem } from "react-bootstrap-dropdown-menu";
import logo from "../images/svgergbot.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/NewUser.css";
import "../styles/index.sass";

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Type: "Rower",
      City: "",
      Age: 0,
      userid: localStorage.getItem("userid"),
    };
  }

  submitInfo = () => {
    return fetch("https://ergbot-server7229.herokuapp.com/users/submitInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ ...this.state }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (!resp.isNew) {
          console.log(
            "You are now signed up! Please read the 'Best Practices' before use and check your email - your new ErgBot workout log has been shared with you!",
            "",
            [{ text: "Close" }]
          );
        }
        localStorage.removeItem("new");
        this.forceUpdate();
        window.location.assign("/");
      });
  };

  render() {
    return (
      <div>
        <Header />
        <div className="page centered">
          <img src={logo} className="logo-jump" alt="logo" />
          <p className="title-text"> Welcome new user! </p>
          <p> Which best describes you? </p>
          <div className="role-dropdown">
            <DropdownMenu triggerType="Text" trigger={this.state.Type}>
              <MenuItem
                text="Rower"
                onClick={() => this.setState({ Type: "Rower" })}
              />
              <MenuItem
                text="Coxswain"
                onClick={() => this.setState({ Type: "Coxswain" })}
              />
              <MenuItem
                text="Coach"
                onClick={() => this.setState({ Type: "Coach" })}
              />
              <MenuItem
                text="Other"
                onClick={() => this.setState({ Type: "Other" })}
              />
            </DropdownMenu>
          </div>
          <p> Where do you live? </p>
          <input
            type="text"
            value={this.state.City}
            onChange={(e) => this.setState({ City: e.target.value })}
            className="location-input"
          />
          <p> How old are you? </p>
          <input
            type="text"
            value={this.state.Age}
            onChange={(e) => this.setState({ Age: e.target.value })}
            className="location-input"
          />
          <input
            className="button green"
            type="submit"
            value="Submit"
            onClick={this.submitInfo}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default NewUser;
