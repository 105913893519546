import React from 'react'
import { Modal } from 'react-bootstrap'

function leaveTeam(teamid, userid, close) {
  fetch(`https://ergbot-server7229.herokuapp.com/teams/removemember/${teamid}`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ userid })
  })
  .catch(err => close())
  .then(resp => resp.json())
  .then(({ team, user}) => {
    window.location.href = '/teams'
  })
}

function removeAdmin(teamid, userid, close) {
  fetch(`https://ergbot-server7229.herokuapp.com/teams/removeadmin/${teamid}`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ userid })
  })
  .catch(err => close())
  .then(resp => resp.json())
  .then(({ team, user}) => {
    window.location.href = '/teams'
  })
}

function isAdmin(userid, team) {
  if (!team.Admins) return false
  const ids = team.Admins.map(admin => admin._id)
  return ids.includes(userid)
}

function isMember(userid, team) {
  if (!team.Roster) return false
  const ids = team.Roster.map(member => member._id)
  return ids.includes(userid)
}

export default function LeaveModal (props) {
  const { open, userid, team, close } = props
  return (
    <Modal className="modal" show={open}>
      <Modal.Header closeButton onClick={close}>
        <Modal.Title>Leave Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { (isAdmin(userid, team) && team.Admins.length < 2) ? <p> You cannot leave this team because you are the only admin. Visit the team page to add a new admin or delete the team. </p> :
          (isAdmin(userid, team) && isMember(userid, team)) ? <p> You are an admin and a member of this team. Would you like to leave as an admin or a member? Only admins can reverse this action. </p> :
          <p> Are you sure you want to leave {team.TeamName}? Only team admins can reverse this action.</p> }
      </Modal.Body>
      <Modal.Footer>
        <a className="link" onClick={close}>
            Cancel
        </a>
        { (isAdmin(userid, team) && isMember(userid, team) && team.Admins.length > 1) ? (
            <span>
              <button className="button" onClick={() => removeAdmin(team._id, userid, close)}>
                  Leave as admin
              </button>
              <button className="button" onClick={() => leaveTeam(team._id, userid, close)}>
                  Leave as member
              </button>
            </span>) :
          (!isAdmin(userid, team) || team.Admins.length > 1) && (
            <button className="button" onClick={() => leaveTeam(team._id, userid, close)}>
                Leave
            </button>
          ) }
      </Modal.Footer>
    </Modal>
  )
}
