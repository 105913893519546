import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/svgergbot.png";
import "../styles/Header.css";

function logout() {
  localStorage.removeItem("userid");
  localStorage.removeItem("new");
  localStorage.removeItem("name");
  window.location.assign("/");
}

const Header = (props) => (
  <div className="header">
    <div className="header-left">
      <Link to="/teams">
        <img src={logo} className="header-logo" alt="logo" />
      </Link>
      <p className="header-text"> Welcome to ErgBot!</p>
    </div>
    <button className="link icon-left" onClick={() => logout()}>
      <i class="fas fa-sign-out-alt"></i>Logout
    </button>
  </div>
);

export default Header;
