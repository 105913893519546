import React from "react";
import { Modal } from "react-bootstrap";

class CreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      addmember: false,
      valid: true,
    };
  }

  createTeam() {
    const { name, addmember } = this.state;
    const { userid, close } = this.props;
    if (!name) {
      this.setState({ valid: false });
      return;
    }
    fetch("https://ergbot-server7229.herokuapp.com/teams/new", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ name, userid, addmember }),
    })
      .catch((err) => this.setState({ err: true }))
      .then((resp) => resp.json())
      .then(() => {
        this.setState({ valid: true, err: false, addmember: false, name: "" });
        close();
      });
  }

  render() {
    const { open, close } = this.props;
    const { valid, name, addmember } = this.state;
    return (
      <Modal className="modal" show={open}>
        <Modal.Header closeButton onClick={close}>
          <Modal.Title>Create Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> Team Name: </p>
          <input
            onChange={(e) => this.setState({ name: e.target.value })}
            value={name}
            style={{ width: "100%" }}
          />
          {!valid && <p style={{ color: "red" }}> Please enter team name. </p>}
          <div style={{ display: "flex", marginTop: 15, alignItems: "center" }}>
            <p> Add yourself as a team member? </p>
            <input
              type="checkbox"
              onChange={(e) => this.setState({ addmember: !addmember })}
              style={{ marginLeft: 10, marginTop: -7 }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a className="link" onClick={close}>
            Cancel
          </a>
          <button className="button" onClick={() => this.createTeam()}>
            Create
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreateModal;
