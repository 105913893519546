import React, { Component } from "react";
import { Image } from "react-bootstrap";
import logo from "../images/svgergbot.png";
import AppStoreButton from "../components/Buttons/AppStoreButton";
import GooglePlayButton from "../components/Buttons/GooglePlayButton";
import eyeIcon from "../images/eye-solid.svg";
import googleIcon from "../images/google-brands.svg";
import teamIcon from "../images/users-solid.svg";
import app from "../images/app-speed.gif";

import LSURowingCrest from "../images/LSUcrest.png";
import MBACrest from "../images/MBACrest.png";
import MontclairCrest from "../images/MontclairCrest.png";
import OregonCrest from "../images/OregonCrest.png";
import NorcalCrest from "../images/NorcalCrest.png";
import McquaidCrest from "../images/McquaidCrest.png";
import NorthAlleghenyCrest from "../images/NorthAlleghanyCrest.png";
import DukesCrest from "../images/DukesCrest.png";
import RobertMorris from "../images/robertmorris.png";
import WalterJohnson from "../images/walterjohnson.png";
import Ontario from "../images/ontario.png";

import Ben from "../images/ben.jpeg";
import Joe from "../images/joe.jpeg";
import Romy from "../images/romy.jpeg";
import Eva from "../images/eva.jpeg";

import Footer from "../components/Footer";
import Feature from "../components/Feature";
import { GoogleLogin } from "react-google-login";
import NoAccessModal from "../components/NoAccessModal";
import Regina from "../images/Regina.png";

const features = [
  {
    title: "Computer-vision powered text detection",
    icon: eyeIcon,
    text: "We use computer vision to read the most important information off of your erg screen in seconds.",
  },
  {
    title: "G-suite integration",
    icon: googleIcon,
    text: "With a click of a button, your erg data is uploaded to a personal Google spreadsheet of all your logged workouts.",
  },
  {
    title: "Team capabilities",
    icon: teamIcon,
    text: "In the newly released Ergbot Team Mode, coaches can track the erg scores of all the players on the team in a convenient, easy to use interface.",
  },
];

const teams = [
  {
    title: "Montclair Crest",
    image: MontclairCrest,
  },
  {
    title: "MBA Crest",
    image: MBACrest,
  },
  {
    title: "Mcquaid Crest",
    image: McquaidCrest,
  },
  {
    title: "Walter Johnson",
    image: WalterJohnson,
  },
  {
    title: "Ontario",
    image: Ontario,
  },
  {
    title: "Robert Morris",
    image: RobertMorris,
  },
  {
    title: "Oregon Rowing",
    image: OregonCrest,
    url: "https://www.uorowing.org/",
  },
  {
    title: "LSU Rowing",
    image: LSURowingCrest,
    url: "http://www.lsurowing.com/",
  },

  {
    title: "Duquesne_Dukes",
    image: DukesCrest,
    url: "https://goduquesne.com/sports/womens-rowing",
  },
  {
    title: "Norcal Rowing",
    image: NorcalCrest,
    url: "https://www.instagram.com/norcal.crew/?hl=en",
  },
];

const stats = [
  {
    number: "8,300+",
    title: "Rowers",
  },
  {
    number: "30+ ",
    title: "Teams",
  },
  {
    number: "70+",
    title: "Countries",
  },
  {
    number: "20,000+",
    title: "Ergs Logged",
  },
];

const team = [
  {
    name: "Ben",
    img: Ben, // copied from linkedin
    role: "President",
    description: [
      "Technical Product Manager @ Amazon",
      "Former AI Research @ Wharton",
      "Penn Lightweight Rowing",
    ],
    linkedinURL: "https://www.linkedin.com/in/ben---barton/",
  },
  {
    name: "Joe",
    img: Joe,
    role: "Vice President",
    description: [
      "CSM @ Amplitude",
      "Former Concept2 World Record Holder",
      "Penn Lightweight Rowing",
    ],
    linkedinURL: "https://www.linkedin.com/in/joseph-ebner/",
  },
  {
    name: "Romy",
    img: Romy,
    role: "Director of Product",
    description: [
      "Product Manager @ BackerKit",
      "Former Google, Goldman Sachs",
      "2x Penn Rowing Captain",
    ],
    linkedinURL: "https://www.linkedin.com/in/romy-s-9b7537131/",
  },
  {
    name: "Eva",
    img: Eva,
    role: "Software Developer",
    description: [
      "Software Engineer @ Replica",
      "Not a rower (but still an athlete!)",
      "Digital nomad + lives in a van",
    ],
    linkedinURL: "https://www.linkedin.com/in/evakill/",
  },
];

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal: false,
      interestEmail: "",
      interestMessage: "",
    };
  }

  loginSuccess = (response) => {
    fetch("https://ergbot-server7229.herokuapp.com/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        user: response.profileObj,
      }),
    })
      .then((resp) => resp.json())
      .then(({ resp, isNew }) => {
        this.setState({ loading: false });
        if (!resp.teammode) {
          this.setState({ modal: true });
        } else {
          localStorage.setItem("userid", resp._id);
          localStorage.setItem("name", resp.Name);
          localStorage.setItem("new", isNew);
          window.location.assign("/teams");
        }
      });
  };

  loginFailure = () => {
    window.confirm("Login failure! Try Again.");
  };

  submitInterestForm = () => {
    const { interestEmail, interestMessage } = this.state;
    if (!interestEmail) {
      this.setState({
        interestError: "Please enter your email",
        interestSuccess: "",
      });
      return;
    }
    fetch("https://ergbot-server7229.herokuapp.com/users/addtointerested", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        email: interestEmail,
        message: interestMessage,
      }),
    }).then((resp) => {
      if (resp.status === 200) {
        this.setState({
          interestEmail: "",
          interestMessage: "",
          interestSuccess: "Thanks for your interest in Ergbot!",
          interestError: "",
        });
      } else {
        this.setState({
          interestEmail: "",
          interestMessage: "",
          interestSuccess: "",
          interestError:
            "Something went wrong! Please try again, or email us at ergbot.rowing@gmail.com",
        });
      }
    });
  };

  render() {
    return (
      <div className="home-page">
        <div className="banner">
          <p>As of March 2024, ErgBot has been acquired by Power Ten Metrics - more to come soon.</p></div>
        <div className="login">
          <GoogleLogin
            clientId="969598334363-r52dpt3h83t9e3dlgohq009nsfkjk11b.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={this.loginSuccess}
            onFailure={this.loginFailure}
            render={(renderProps) => (
              <button className="link" onClick={renderProps.onClick}>
                <i class="fas fa-sign-in-alt"></i> Team Mode Login
              </button>
            )}
          />
        </div>
        <div className="home-page-main">
          <div className="home-page-main-left">
            <div className="home-page-title-container">
              <img src={logo} className="logo-big" alt="logo" />
              <div>
                <div className="home-page-title">ErgBot</div>
                <div className="home-page-subtitle">
                  The Automatic Erg Logger
                </div>
              </div>
            </div>

            <div className="home-page-left-buttons">
              <AppStoreButton />
              <GooglePlayButton />
              <button className="button home-page-button">
                <a href="https://calendly.com/benjaminsbarton/ergbotteammode">
                  Free Team Mode Signup
                </a>
              </button>
            </div>
          </div>
          <div className="home-page-main-right">
            <img className="app-gif" src={app} alt="iphone-demo" />
          </div>
        </div>

        <div className="home-page-pane">
          <div className="pane-title">Welcome to ErgBot</div>
          <div className="pane-subtitle">
            ErgBot allows you and your whole team to log ergs at the snap of a
            picture - for free!
          </div>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              title="youtube"
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/0UdAKyir9Lw"
            />
          </div>
        </div>

        <div className="home-page-pane">
          <div className="pane-title">ErgBot Features</div>

          <div className="features-pane">
            {features.map((feature) => (
              <Feature
                title={feature.title}
                icon={feature.icon}
                text={feature.text}
              ></Feature>
            ))}
          </div>
        </div>

        <div className="home-page-pane">
          <div className="pane-title">ErgBot by the Numbers</div>
          <div className="pane-stats">
            {stats.map((stat) => (
              <div className="stat">
                <div className="stat-number">{stat.number}</div>
                {stat.title}
              </div>
            ))}
          </div>
        </div>

        <div className="home-page-pane">
          <div className="pane-title">Team Ergbot</div>
          <div className="pane-subtitle">
            We know both rowing and tech at the highest levels
          </div>
          <div className="pane-team">
            {team.map(({ name, img, role, description, linkedinURL }) => (
              <div className="profile-box">
                <img src={img} className="profile-img" alt={name} />
                <p className="profile-name">{name}</p>
                <p className="profile-role">{role}</p>
                {description.map((item) => (
                  <p className="profile-text">{item}</p>
                ))}
                <a href={linkedinURL}>
                  <i class="fa-brands fa-linkedin profile-link"></i>
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="home-page-pane">
          <div className="pane-title">Testimonials</div>
          <div className="testimonial-pane">
            <Image className="testimonial-image" src={Regina} />
            <div>
              <p className="testimonial-text">
                ErgBot is a fantastic training tool. I always take pictures of
                my screens but then lose them or never do anything with them. I
                always have my phone and now I have all my data neatly organized
                in one easy place. I love it!
              </p>
              <i className="testimonial-author">
                Regina Salmons, US Olympian, U23 3 Time Gold Medalist
              </i>
            </div>
          </div>
        </div>

        <div className="home-page-pane" id="demo">
          <div className="pane-title">Interested in Team Mode?</div>
          <div className="pane-subtitle">
            <a href="https://calendly.com/power-ten-metrics/30-minute-meeting">
              Click here to book a time for a Team Mode demo and onboarding for
              free!
            </a>
          </div>
        </div>
        <Footer />
        {this.state.modal && (
          <NoAccessModal
            open={this.state.modal}
            close={() => this.setState({ modal: false })}
          />
        )}
      </div>
    );
  }
}

export default HomePage;
