import React from "react";
import googlePlayIcon from "../../images/google-play.svg";

const play_store_href =
  "https://play.google.com/store/apps/details?id=com.ergbot777&hl=en_US";

const GooglePlayButton = () => (
  <button
    onClick={() => window.location.assign(play_store_href)}
    className="button home-page-button"
  >
    <img height="25px" src={googlePlayIcon} alt="google-play" />
    Google Play
  </button>
);

export default GooglePlayButton;
