import React from 'react'
import { Modal } from 'react-bootstrap'

function deleteTeam(teamid, close) {
  fetch(`https://ergbot-server7229.herokuapp.com/teams/delete/${teamid}`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    }
  })
  .catch(err => {})
  .then(() => {
    close()
    window.location.href = '/teams'
  })
}

export default function DeleteTeamModal (props) {
  const { open, close, teamid } = props
  return (
    <Modal className="modal" show={open}>
      <Modal.Header closeButton onClick={close}>
        <Modal.Title>Delete Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p> Are you sure you want to delete this team? This cannot be undone. </p>
      </Modal.Body>
      <Modal.Footer>
        <a className="link" onClick={close}>
            Cancel
        </a>
        <button className="button" onClick={() => deleteTeam(teamid, close)}>
            Delete
        </button>
      </Modal.Footer>
    </Modal>
  )
}
