export const OVERALL_HEADERS = [
  "Member Name",
  "Date Uploaded",
  "Overall Time",
  "Overall Meter",
  "Overall /500m",
  "Overall s/m",
];

export const ALL_HEADERS = [
  "Member Name",
  "Date Uploaded",
  "Workout Name",
  "Overall Time",
  "Overall Meter",
  "Overall /500m",
  "Overall s/m",
  "P1 Time",
  "P1 Meter",
  "P1 /500m",
  "P1 s/m",
  "P2 Time",
  "P2 Meter",
  "P2 /500m",
  "P2 s/m",
  "P3 Time",
  "P3 Meter",
  "P3 /500m",
  "P3 s/m",
  "P4 Time",
  "P4 Meter",
  "P4 /500m",
  "P4 s/m",
  "P5 Time",
  "P5 Meter",
  "P5 /500m",
  "P5 s/m",
  "P6 Time",
  "P6 Meter",
  "P6 /500m",
  "P6 s/m",
  "P7 Time",
  "P7 Meter",
  "P7 /500m",
  "P7 s/m",
  "P8 Time",
  "P8 Meter",
  "P8 /500m",
  "P8 s/m",
];

export const TIME_FORMAT = "ddd, M/D/YY, h:mma";
