import React, { Component } from "react";
import "../styles/Upload.sass";
import camera from "../images/camera.png";
import Loading from "./Loading";
import EditLogModal from "./EditLogModal";

const DEFAULT_STATE = {
  uploading: true,
  processing: false,
  confirming: false,
  success: false,
  error: false,
  fileUrl: undefined,
  file: undefined,
  logData: undefined,
};

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  handleFileUpload = (e) => {
    const data = new FormData();
    data.append("photo", e.target.files[0]);
    this.setState({
      file: data,
      fileUrl: URL.createObjectURL(e.target.files[0]),
      error: false,
    });
  };

  submitImage = () => {
    this.setState({ uploading: false, processing: true });
    const { file } = this.state;
    fetch("https://ergbot-server7229.herokuapp.com/logs/photo", {
      method: "POST",
      body: file,
      header: {
        "content-type": "multipart/form-data",
      },
    })
      .catch((err) => this.setState({ ...DEFAULT_STATE, error: true }))
      .then((resp) => resp.json())
      .then((resp) => {
        this.setState({
          processing: false,
          confirming: true,
          fileUrl: resp.path,
          logData: resp.data,
        });
      });
  };

  saveLog = (data) => {
    this.setState({ confirming: false, processing: true });
    const { fileUrl } = this.state;
    const { userid, teamid } = this.props;
    fetch("https://ergbot-server7229.herokuapp.com/logs/upload", {
      method: "POST",
      body: JSON.stringify({
        teamid,
        userid,
        data,
        path: fileUrl,
      }),
      headers: {
        "content-type": "application/json; charset=utf-8",
      },
    })
      .then((resp) => {
        if (resp.status === 200) {
          this.setState({ ...DEFAULT_STATE, success: true });
          this.props.getTeam();
        } else {
          this.setState({ ...DEFAULT_STATE, error: true });
        }
      })
      .catch(() => this.setState({ ...DEFAULT_STATE, error: true }));
  };

  render() {
    const {
      uploading,
      processing,
      confirming,
      error,
      success,
      fileUrl,
      logData,
    } = this.state;
    return (
      <div className="upload-container">
        <p>
          Make sure numbers on erg screen are clear!
        </p>
        {error && (
          <p className="error">
            Sorry, there was an error processing your photo. Please try again or submit
            a different photo.
          </p>
        )}
        {success && <p className="success">Log uploaded successfully!</p>}
        {uploading ? (
          <div>
            <img src={fileUrl ?? camera} alt="Camera" className="camera" />
            <input
              className="file-upload-button"
              type="file"
              onChange={this.handleFileUpload}
            />
            <button
              className={`button green ${!fileUrl ? "disabled" : ""}`}
              onClick={this.submitImage}
              disabled={!fileUrl}
            >
              Upload
            </button>
          </div>
        ) : processing ? (
          <>
            <Loading />
            <p>Please wait while we process your photo.</p>
          </>
        ) : confirming ? (
          <EditLogModal
            confirm
            open
            data={logData}
            img={fileUrl}
            onClose={() => this.setState(DEFAULT_STATE)}
            onSave={this.saveLog.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export default Upload;
