//create react component for loading screen

import React from 'react'
import ReactLoading from "react-loading";
import logo from '../images/svgergbot.png'
import styleSheet from '../styles/Loading.css'

const LoadingScreen = () => (
      
        <div style={{justifyContent: 'center', width: '100%' }}>
            <div className="loading-screen-container">
                
                  
                <img src={logo} className="load-logo" alt="logo" />
                
                <div className="loading-screen-spinner">
                    <ReactLoading type="spin" color="#a7be8f" height={60} width={60} />
                </div>

            
            </div>
        </div>

)

export default LoadingScreen
