import React, { Component } from 'react'
import EditMemberModal from './EditMemberModal'
import Name from './Name'

class MembersTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editMemberModal: false,
            member: ''
        }
    }

    render() {
        const {member, editMemberModal} = this.state
        const {team, isAdmin, getTeam} = this.props
        const { Roster } = team
        if (!Roster) return;

        return (
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Roster.map(m => (
                            <tr>
                                <td>
                                    <Name member={m} aliases={team.Aliases} />
                                </td>
                                <td>{m.Email}</td>
                                {isAdmin && (
                                    <td>
                                        <i className="fas fa-pen icon" onClick={(e) => {
                                            e.stopPropagation()
                                            this.setState({ editMemberModal: true, member: m })
                                        }}></i>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
                { editMemberModal && <EditMemberModal
                    open={editMemberModal}
                    close={() => {
                        this.setState({ editMemberModal: false, member: ''})
                        getTeam()
                    }}
                    member={member}
                    team={team}
                    /> }
            </div>
        )
    }
}

export default MembersTable
