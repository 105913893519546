import React from "react";
import { Modal } from "react-bootstrap";
import { getName } from "../utils";

class EditMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: getName(props.team.Aliases, props.member),
    };
  }

  editMember() {
    const { name } = this.state;
    const { team, close } = this.props;
    if (!name) return;
    fetch(
      `https://ergbot-server7229.herokuapp.com/teams/addalias/${team._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ name, userid: this.props.member._id }),
      }
    )
      .catch((err) => this.setState({ err: true }))
      .then((resp) => resp.json())
      .then(() => {
        this.setState({ name: "" });
        close();
      });
  }

  render() {
    const { open, close } = this.props;
    const { name } = this.state;
    return (
      <Modal className="modal" show={open}>
        <Modal.Header closeButton onClick={close}>
          <Modal.Title>Edit Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> Member Name: </p>
          <input
            onChange={(e) => this.setState({ name: e.target.value })}
            value={name}
            style={{ width: "100%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <a className="link" onClick={close}>
            Cancel
          </a>
          <button className="button" onClick={() => this.editMember()}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditMember;
