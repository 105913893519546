import React from "react";
import appleIcon from "../../images/appleIcon.svg";
const app_store_href = "https://apps.apple.com/us/app/ergbot/id1435719133";

const AppStoreButton = () => (
  <button
    onClick={() => window.location.assign(app_store_href)}
    className="button home-page-button"
  >
    <img className="button-img" height="25px" src={appleIcon} alt="apple" />
    App Store
  </button>
);

export default AppStoreButton;
