import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { ALL_HEADERS } from "../constants";
import "../styles/Upload.sass";

class EditLogModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      title: props.data[0],
      img: props.img,
      errs: [],
    };
  }

  updateValue(i, value) {
    let { data } = this.state;
    data[i] = value;
    this.setState({ data });
  }

  // mongoDB doesn't like arrays with "missing" (null) values. raise errors if user leaves missing
  // values in the data
  verifyData = () => {
    let { data } = this.state;
    let last = false;
    const errs = [];
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i]) last = true;
      if (last && !data[i]) errs.push(i);
    }
    if (errs.length) this.setState({ errs });
    else {
      this.props.onSave(data);
    }
  };

  render() {
    const { data, img, title, errs } = this.state;
    const { onClose, open, onDelete, confirm } = this.props;
    // remove user and date headers - not configurable
    const headers = ALL_HEADERS.slice(2);
    return (
      <Modal show={open}>
        <Modal.Header closeButton onClick={onClose}>
          <Modal.Title>
            {confirm ? "Confirm" : "Edit"} {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="big-modal">
          <div className="columns">
            {img ? (
              <div className="sticky">
                <img src={img} alt="log" width="300px" />
              </div>
            ) : (
              ""
            )}
            <table className="table">
              <tbody>
                {headers.map((header, i) => (
                  <tr>
                    <th>{header}</th>
                    <td>
                      <input
                        value={data[i]}
                        onChange={(e) => this.updateValue(i, e.target.value)}
                        style={{
                          width: "100%",
                        }}
                      />
                      {errs?.includes(i) ? (
                        <p className="error">Please fill out this field</p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a className="link" onClick={onClose}>
            Cancel
          </a>
          {!confirm ? (
            <button className="button" onClick={onDelete}>
              Delete Log
            </button>
          ) : null}
          <button className="button" onClick={this.verifyData.bind(this)}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditLogModal;
