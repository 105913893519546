import React from "react";
import { GoogleLogin } from "react-google-login";
import GoogleButton from "react-google-button";
import logo from "../images/svgergbot.png";
import Header from "../components/Header";
import Loading from "../components/Loading";
import Footer from "../components/Footer";
import NoAccessModal from "../components/NoAccessModal";
import "../styles/index.sass";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal: false,
    };
  }

  loginSuccess = (response) => {
    fetch("https://ergbot-server7229.herokuapp.com/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        user: response.profileObj,
      }),
    })
      .then((resp) => resp.json())
      .then(({ resp, isNew }) => {
        this.setState({ loading: false });
        if (!resp.teammode) {
          this.setState({ modal: true });
        } else {
          localStorage.setItem("userid", resp._id);
          localStorage.setItem("name", resp.Name);
          localStorage.setItem("new", isNew);
          window.location.assign("/teams");
        }
      });
  };

  loginFailure = () => {
    window.confirm("Login failure! Try Again.");
  };

  render() {
    if (this.state.loading) return <Loading />;
    return (
      <div>
        <div className="no-header" />
        <div className="page centered">
          <p className="title-text">Welcome to Ergbot!</p>
          <img src={logo} className="logo-spin" alt="logo" />
          <GoogleLogin
            clientId="969598334363-r52dpt3h83t9e3dlgohq009nsfkjk11b.apps.googleusercontent.com"
            onSuccess={this.loginSuccess}
            onFailure={this.loginFailure}
            render={(renderProps) => (
              <GoogleButton onClick={renderProps.onClick} />
            )}
          />
        </div>
        <Footer />
        {this.state.modal && (
          <NoAccessModal
            open={this.state.modal}
            close={() => this.setState({ modal: false })}
          />
        )}
      </div>
    );
  }
}

export default Login;
