import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.sass";
import "./styles/Home.sass";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import Login from "./pages/Login";
import NewUser from "./pages/NewUser";
import Teams from "./pages/Teams";
import TeamPage from "./pages/TeamPage";
import Home from "./pages/Home";

function App(props) {
  const userid = localStorage.getItem("userid");
  if (!userid) {
    return (
      <Switch>
        <Route path="/" exact render={() => <Home />} />
        <Route path="/login" render={() => <Login />} />
        <Redirect to="/" />
      </Switch>
    );
  }
  const isNew = localStorage.getItem("new");
  if (isNew === "true") {
    return (
      <>
        <Redirect to="/newuser" />
        <Route path="/newuser" exact render={() => <NewUser />} />
      </>
    );
  }
  return (
    <Switch>
      <Route path="/teams" exact render={() => <Teams />} />
      <Route path="/teams/:id" render={() => <TeamPage />} />
      <Redirect to="/teams" />
    </Switch>
  );
}

ReactDOM.render(
  <Router>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// eva was here
