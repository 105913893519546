import React from 'react'
import { Modal } from 'react-bootstrap'

export default function DeleteLogModal (props) {
  const { open, close, deleteLog, logid } = props
  return (
    <Modal className="modal" show={open}>
      <Modal.Header closeButton onClick={close}>
        <Modal.Title>Delete Log</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p> Are you sure you want to delete this log? This cannot be undone. </p>
      </Modal.Body>
      <Modal.Footer>
        <a className="link" onClick={close}>
            Cancel
        </a>
        <button className="button" onClick={() => deleteLog(logid)}>
            Delete
        </button>
      </Modal.Footer>
    </Modal>
  )
}
