import "../styles/Footer.sass";
import "../styles/index.sass";

const Footer = () => (
  <div className="footer">
    <div className="footer-links">
      <a
        className="link"
        href="https://itunes.apple.com/us/app/ergbot/id1435719133?mt=8"
        target="_blank"
        rel="noreferrer"
      >
        Download the App
      </a>
      <a
        className="link"
        href="https://www.instagram.com/ergbot/"
        target="_blank"
        rel="noreferrer"
      >
        Instagram
      </a>
      <a
        className="link"
        href="mailto:ergbot.rowing@gmail.com"
        target="_blank"
        rel="noreferrer"
      >
        Contact Us
      </a>
    </div>
    <p>© Ergbot 2018</p>
  </div>
);

export default Footer;
