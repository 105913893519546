import React from "react";
const Feature = (props) => (
  <div className="feature-box">
    <img src={props.icon} className="feature-icon" alt="feature" />
    <div className="feature-text">
      <b className="feature-title">{props.title}</b>
      <p className="feature-description">{props.text}</p>
    </div>
  </div>
);

export default Feature;
