import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LeaveModal from "../components/LeaveTeamModal";
import AddMembersModal from "../components/AddMembersModal";
import RemoveMembersModal from "../components/RemoveMembersModal";
import DeleteTeamModal from "../components/DeleteTeamModal";
import LogsTable from "../components/LogsTable";
// import AssignmentsTable from "../components/AssignmentsTable";
import MembersTable from "../components/MembersTable";
import Loading from "../components/Loading";
import Upload from "../components/Upload";
import { DropdownMenu, MenuItem } from "react-bootstrap-dropdown-menu";
import "../styles/Teams.sass";
import "../styles/index.sass";
import { getName } from "../utils";

export default function TeamPage(props) {
  let { id } = useParams();

  return (
    <div>
      <Header />
      <Page teamid={id} userid={localStorage.getItem("userid")} />
      <Footer />
    </div>
  );
}

//const tabs = ["Logs", "Members", "Assignments", "Info"];
const tabs = ["Logs", "Members", "Upload", "Info"];
class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "Logs",
      userid: props.userid,
      changeName: false,
    };
    this.getTeam();
  }

  getTeam() {
    const { teamid } = this.props;
    fetch(`https://ergbot-server7229.herokuapp.com/teams/team/${teamid}`)
      .then((resp) => resp.json())
      .then((team) => this.setState({ team }));
  }

  isAdmin() {
    const { team, userid } = this.state;
    if (!team.Admins) return false;
    const ids = team.Admins.map((admin) => admin._id);
    return ids.includes(userid);
  }

  closeModal() {
    this.setState({
      leaveModal: false,
      addMembersModal: false,
      addAdminsModal: false,
      removeMembersModal: false,
      deleteTeamModal: false,
    });
    this.getTeam();
  }

  changeName() {
    const { teamName } = this.state;
    const { teamid } = this.props;
    fetch(`https://ergbot-server7229.herokuapp.com/teams/name/${teamid}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ name: teamName }),
    })
      .then((resp) => resp.json())
      .then((team) => {
        this.getTeam();
        this.setState({ changeName: false });
      });
  }

  render() {
    const adminOptions = [
      {
        name: "Change name",
        action: (team) =>
          this.setState({ changeName: true, teamName: team.TeamName }),
      },
      {
        name: "Add admin",
        action: () => this.setState({ addAdminsModal: true }),
      },
      {
        name: "Add members",
        action: () => this.setState({ addMembersModal: true }),
      },
      {
        name: "Remove members",
        action: () => this.setState({ removeMembersModal: true }),
      },
      {
        name: "Delete Team",
        action: () => this.setState({ deleteTeamModal: true }),
      },
    ];

    const {
      team,
      activeTab,
      leaveModal,
      addMembersModal,
      addAdminsModal,
      changeName,
      teamName,
      userid,
      removeMembersModal,
      deleteTeamModal,
    } = this.state;
    if (!team) return <Loading />;
    return (
      <div className="page gray">
        <Link to="/teams" className="text-button">
          <p>
            {" "}
            <i className="fas fa-angle-double-left"></i> Teams{" "}
          </p>
        </Link>
        <div className="teams-header">
          {changeName ? (
            <div className="change-name-input">
              <input
                className="title-text"
                value={teamName}
                onChange={(e) => this.setState({ teamName: e.target.value })}
              />
              <button
                className="button green"
                onClick={() => this.changeName()}
              >
                Save
              </button>
            </div>
          ) : (
            <p className="title-text">{team.TeamName}</p>
          )}
          <div className="teams-icon-group">
            {this.isAdmin() && (
              <DropdownMenu
                className="dropdown"
                position="left"
                fadein
                css={{
                  menuContent: {
                    boxShadow: "none",
                    backgroundColor: "white",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                  },
                  separator: {
                    width: "90%",
                    borderTop: "1px solid light-grey",
                  },
                }}
              >
                {adminOptions.map((option) => (
                  <MenuItem
                    text={option.name}
                    onClick={() => option.action(team)}
                    linkStyle={{
                      fontSize: 16,
                      cursor: "pointer",
                      fontWeight: "600px",
                    }}
                  />
                ))}
              </DropdownMenu>
            )}
            <i
              className="far fa-times-circle icon icon-large"
              onClick={() => this.setState({ leaveModal: true })}
            ></i>
          </div>
        </div>
        <ul
          className="nav nav-tabs"
          style={{ width: "100%", marginBottom: 30 }}
        >
          {tabs.map((name) => (
            <li className={name === activeTab ? "active" : "not-active"}>
              <a
                onClick={() => this.setState({ activeTab: name })}
                data-toggle="tab"
              >
                {name}
              </a>
            </li>
          ))}
        </ul>
        <div className="tab-content">
          <li className={"Logs" === activeTab ? "tab-pane active" : "tab-pane"}>
            <LogsTable
              team={team}
              isAdmin={this.isAdmin()}
              getTeam={this.getTeam.bind(this)}
            />
          </li>
          <li
            className={"Members" === activeTab ? "tab-pane active" : "tab-pane"}
          >
            <MembersTable
              team={team}
              isAdmin={this.isAdmin()}
              getTeam={this.getTeam.bind(this)}
            />
          </li>
          <li
            className={"Upload" === activeTab ? "tab-pane active" : "tab-pane"}
          >
            <Upload
              userid={userid}
              teamid={team._id}
              getTeam={this.getTeam.bind(this)}
            />
          </li>
          <li className={"Info" === activeTab ? "tab-pane active" : "tab-pane"}>
            {Info(team)}
          </li>
          {/*
          <li
            className={
              "Assignments" === "Assignments" ? "tab-pane active" : "tab-pane"
            }
          >
            <AssignmentsTable
              team={team}
              logs={team.Logs}
              isAdmin={this.isAdmin()}
              getTeam={this.getTeam.bind(this)}
            />
          </li>
          */}
        </div>

        {leaveModal && (
          <LeaveModal
            open={leaveModal}
            userid={userid}
            team={team}
            close={() => this.closeModal()}
          />
        )}

        {addMembersModal && (
          <AddMembersModal
            type="member"
            userid={userid}
            open={addMembersModal}
            team={team}
            close={() => this.closeModal()}
          />
        )}

        {addAdminsModal && (
          <AddMembersModal
            type="admin"
            userid={userid}
            open={addAdminsModal}
            team={team}
            close={() => this.closeModal()}
          />
        )}

        {removeMembersModal && (
          <RemoveMembersModal
            open={removeMembersModal}
            team={team}
            close={() => this.closeModal()}
          />
        )}

        {deleteTeamModal && (
          <DeleteTeamModal
            open={deleteTeamModal}
            teamid={team._id}
            close={() => this.closeModal()}
          />
        )}
      </div>
    );
  }
}

function Info(team) {
  const { Admins, Roster, Logs } = team;
  if (!Admins) return;
  return (
    <div>
      <table className="table">
        <tbody>
          <tr>
            <th> Admins </th>
            <td>
              {Admins.map((admin, i) => getName(team.Aliases, admin)).join(
                ", "
              )}
            </td>
          </tr>
          <tr>
            <th> # of Members </th>
            <td> {Roster.length} </td>
          </tr>
          <tr>
            <th> # of Logs </th>
            <td> {Logs.length} </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
