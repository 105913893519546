import React from 'react'
import { Modal } from 'react-bootstrap'
import Name from './Name'

class RemoveMembersModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            members: props.team.Roster,
            toRemove: []
        }
    }

    removeMember(memberid) {
        let { members, toRemove } = this.state
        members = members.filter(member => member._id !== memberid)
        toRemove.push(memberid)
        this.setState({ members, toRemove })
    }

    async done () {
        const { team, close } = this.props
        const { toRemove } = this.state
        for (let i = 0; i < toRemove.length; i++) {
            await fetch(`https://ergbot-server7229.herokuapp.com/teams/removemember/${team._id}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({ userid: toRemove[i]})
            })
        }
        this.setState({ toRemove: [], err: ''})
        close()
    }

    render() {
        const { open, close, team } = this.props
        const { members } = this.state
        return (
            <Modal className="modal" show={open}>
                <Modal.Header closeButton onClick={close}>
                    <Modal.Title>Remove Members</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                { members && members.map(member => <tr>
                                    <td>
                                        <Name aliases={team.Aliases} member={member} />
                                    </td>
                                    <td>
                                        <i className="far fa-times-circle icon" onClick={() => this.removeMember(member._id)}></i>
                                    </td>
                                </tr>) }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a className="link" onClick={close}>
                        Cancel
                    </a>
                    <button className="button" onClick={() => this.done()}>
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default RemoveMembersModal
