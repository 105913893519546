import React from "react";
import { Modal } from "react-bootstrap";
import Name from "./Name";
import Papa from "papaparse";

class AddMembersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      valid: true,
      members: [],
      err: "",
      multipleMembers: [],
    };
  }

  addMembers() {
    const { email } = this.state;
    const { team, type, userid } = this.props;
    fetch(
      `https://ergbot-server7229.herokuapp.com/teams/add${type}/${team._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ email }),
      }
    )
      .then((resp) => resp.json())
      .then(({ err, user, team }) => {
        if (err) return this.setState({ err });
        let members = this.state.members;
        members.push(user);
        this.setState({ err: "", members, email: "", user });
      })
      .catch((err) => this.setState({ err: err.message }));
  }

  invite() {
    const { team, type, userid } = this.props;
    let { members, email, user } = this.state;
    if (!email) {
      email = user.Email;
    }
    fetch(
      `https://ergbot-server7229.herokuapp.com/teams/invitemember/${team._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ userid, email, type }),
      }
    )
      .then((resp) => resp.json())
      .then((user) => {
        members.push(user);
        this.setState({ err: "", members, email: "", user: null });
      })
      .catch((err) => this.setState({ err: err.message }));
  }

  async cancel() {
    const { team, close, type } = this.props;
    const { members } = this.state;
    for (const i in members) {
      const userid = members[i]._id;
      await fetch(
        `https://ergbot-server7229.herokuapp.com/teams/remove${type}/${team._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ userid }),
        }
      );
    }
    close();
  }

  done() {
    const { team, close, type } = this.props;
    const { email, members } = this.state;
    for (const i in members) {
      const userid = members[i]._id;
      fetch(
        `https://ergbot-server7229.herokuapp.com/teams/notifymember/${team._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ userid, type }),
        }
      ).catch((err) => console.log(err));
    }
    close();
  }

  // function serves to update the relevant state
  // necessary to pass information between the onclick method
  // so that each individual member can either be invited or added
  updateMembersToAdd(event) {
    var toAdd = Array();

    // headers are currently required with the necessary field being "email"
    // should figure out how to notify customers of this
    // alternatively can do it without headers but that would be more problematic
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        results.data.forEach((person) => {
          toAdd.push(person["email"]);
        });
      },
    });

    this.setState({ multipleMembers: toAdd });
  }

  addMultipleMembers() {
    const { team, type, userid } = this.props;
    const { members, multipleMembers } = this.state;
    console.log(multipleMembers);

    multipleMembers.forEach((email) => {
      fetch(
        `https://ergbot-server7229.herokuapp.com/teams/add${type}/${team._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ email }),
        }
      )
        .then((resp) => resp.json())
        .then(({ err, addedUser }) => {
          // if error occurs that means that the user is not actively using ergbot
          // therefore they must be invited
          if (err) {
            fetch(
              `https://ergbot-server7229.herokuapp.com/teams/invitemember/${team._id}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({ userid, email, type }),
              }
            )
              .then((resp) => resp.json())
              .then((user) => {
                members.push(user);
                this.setState({ err: "", members, email: "", user: null });
              })
              .catch((err) => this.setState({ err: err.message }));
          } else {
            let members = this.state.members;
            members.push(addedUser);
            this.setState({ err: "", members, email: "", addedUser });
          }
        });
    });
  }

  render() {
    const { open, close, type, team } = this.props;
    const { email, err, members } = this.state;
    return (
      <Modal className="modal" show={open}>
        <Modal.Header closeButton onClick={close}>
          <Modal.Title>
            {type === "member" ? "Add Members" : "Add Admins"}
          </Modal.Title>
        </Modal.Header>
        {err === "User not found." || err === "Invited user." ? (
          <>
            <Modal.Body>
              <p>
                There in no Ergbot account linked to this email. Would you like
                to send an email invite to {email} and add them to the team?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="link"
                onClick={() => this.setState({ err: "", email: "" })}
              >
                Cancel
              </button>
              <button className="button" onClick={() => this.invite()}>
                Send invite
              </button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              <p>Email:</p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  style={{ width: "100%", height: 30 }}
                />
                <button
                  className="button green small"
                  onClick={() => this.addMembers()}
                >
                  Add
                </button>
              </div>
              {err && <p style={{ color: "red" }}> {err} </p>}
              {members.map((member) => (
                <Name aliases={team.Aliases} member={member} />
              ))}
            </Modal.Body>
            <Modal.Footer>
              <div>
                <input
                  type="file"
                  name="file"
                  accept=".csv"
                  onChange={(e) => this.updateMembersToAdd(e)}
                  style={{
                    display: "block",
                    margin: "10px auto",
                    width: "190px",
                  }}
                />
              </div>
              <button className="button" onClick={this.addMultipleMembers}>
                Add Multiple Members
              </button>
              <button className="button" onClick={() => this.cancel()}>
                Cancel
              </button>
              <button className="button" onClick={() => this.done()}>
                Done
              </button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    );
  }
}

export default AddMembersModal;
