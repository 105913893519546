import React from 'react'
import { Modal } from 'react-bootstrap'

export default function NoAccessModal (props) {
  const { open, close } = props
  return (
    <Modal className="modal" show={open}>
      <Modal.Header closeButton onClick={close}>
        <Modal.Title>Subscribe to Team Mode!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You need a subscription to access Ergbot Team Mode web platform. If you're interested to learn more about Team Mode or sign up, email{' '}<a href="mailto:ergbot.rowing@gmail.com">ergbot.rowing@gmail.com</a></p>
      </Modal.Body>
    </Modal>
  )
}
