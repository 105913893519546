import React, { Component } from "react";
import Header from "../components/Header";
import LoadingScreen from "../components/LoadingScreen";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import LeaveModal from "../components/LeaveTeamModal";
import CreateModal from "../components/CreateTeamModal";
import { getName } from "../utils";

import "../styles/Teams.sass";
import "../styles/index.sass";

class Teams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userid: localStorage.getItem("userid"),
      createModal: false,
      leaveModal: false,
      done: undefined,
      leaveTeam: {},
    };
  }

  componentDidMount() {
    this.getTeams();
  }

  getTeams() {
    const { userid } = this.state;
    setTimeout(() => {
      fetch(
        `https://ergbot-server7229.herokuapp.com/teams/${userid}?adminteams=true`
      )
        .then((json) => json.json())
        .then((teams) => {
          this.setState({ teams });
          this.setState({ done: true });
          this.forceUpdate();
        });
    }, 1000);
  }

  async getTeam(id) {
    let team = await fetch(
      `https://ergbot-server7229.herokuapp.com/teams/team/${id}`
    );
    return team;
  }

  closeModal() {
    this.setState({ leaveModal: false, createModal: false });
    this.getTeams();
  }

  render() {
    const { teams, userid, createModal, leaveModal, leaveTeam } = this.state;
    return (
      <div>
        {!this.state.done ? (
          <LoadingScreen />
        ) : (
          <div>
            <Header />
            <div className="page gray">
              <div className="teams-header">
                <p className="title-text">Your Teams</p>
                <div
                  className="button green"
                  onClick={() => this.setState({ createModal: true })}
                >
                  New Team
                </div>
              </div>
              <div className="teams-table-container">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="header-col" scope="col">
                        Name
                      </th>
                      <th className="header-col" scope="col">
                        Admin
                      </th>
                      <th className="header-col" scope="col">
                        # Members
                      </th>
                      <th className="header-col" scope="col">
                        # Logs
                      </th>
                      <th className="header-col" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!teams && <Loading />}
                    {teams &&
                      teams.map((team) => {
                        const { _id, TeamName, Roster, Admins, Logs } = team;
                        const link = `/teams/${_id}`;
                        return (
                          <tr>
                            <td
                              scope="row"
                              style={{ cursor: "pointer" }}
                              onClick={() => (window.location.href = link)}
                            >
                              {TeamName}
                            </td>
                            <td
                              scope="row"
                              style={{ cursor: "pointer" }}
                              onClick={() => (window.location.href = link)}
                            >
                              {Admins.map((admin, i) =>
                                getName(team.Aliases, admin)
                              ).join(", ")}
                            </td>
                            <td
                              scope="row"
                              style={{ cursor: "pointer" }}
                              onClick={() => (window.location.href = link)}
                            >
                              {Roster.length}
                            </td>
                            <td
                              scope="row"
                              style={{ cursor: "pointer" }}
                              onClick={() => (window.location.href = link)}
                            >
                              {Logs.length}
                            </td>
                            <td>
                              <i
                                className="far fa-times-circle icon"
                                onClick={() =>
                                  this.setState({
                                    leaveModal: true,
                                    leaveTeam: team,
                                  })
                                }
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <Footer />

            <CreateModal
              open={createModal}
              userid={userid}
              close={() => this.closeModal()}
            />

            <LeaveModal
              open={leaveModal}
              userid={userid}
              team={leaveTeam}
              close={() => this.closeModal()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Teams;
